const layoutPCStyle = {
  layoutRoot: {
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  
  logo: {
    height: 60
  },

  thunmbnail: {
    width: 108,
    height: 108,
    lineHeight: "108px"
  },

  copyrightMessage: {
    verticalAlign: "top",
    fontFamily: "Noto Sans KR",
    fontSize: "15px"
  },
}
export default layoutPCStyle;