// =========================================================
import Photo from "layout/admin/Photo.js"
import Model from "layout/admin/Model.js"
import Album from "layout/admin/Album.js"
import MyPage from "layout/admin/MyPage.js"
// =========================================================

const routes = [
  {
    path: "/photo",
    name: "갤러리",
    icon: "photo_camera",
    component: Photo,
    layout: "/admin"
  },
  {
    path: "/model",
    name: "모델",
    icon: "person_pin",
    component: Model,
    layout: "/admin"
  },
  {
    path: "/album",
    name: "앨범",
    icon: "photo_album",
    component: Album,
    layout: "/admin"
  },
  {
    path: "/mypage",
    name: "관리자설정",
    icon: "build",
    component: MyPage,
    layout: "/admin"
  },
];
export default routes;
