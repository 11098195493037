import React from "react";
import MediaQuery from "react-responsive"

import LayoutPC from "layout/LayoutPC.js"
import LayoutMobile from "layout/LayoutMobile.js"

export default function Layout(props) {
  return (
    <div>
      <MediaQuery minWidth={1025}>
        <LayoutPC />
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <LayoutMobile />
      </MediaQuery>
    </div>
  )
}
