import React from "react";
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Avatar from '@material-ui/core/Avatar';
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import upArrow from "layout/resource/image/pc_up_b.png";
import downArrow from "layout/resource/image/pc_down_b.png";
import crypto from 'crypto-js';
import { green } from '@material-ui/core/colors';

export default class ImageSet extends React.Component {
  state = {
    album                   : null,
    albumId                 : null,
    authorized              : true,
    password                : null,
    authorizeError          : null,

    selectedIndex           : 0,
    totalImageCount         : 0,
    fetchedImages           : [],
    fetchedImageIndex       : 0,
    fectchingImage          : false,

    verticalViewRegionRef   : React.createRef(),
    verticalViewStartIndex  : 0,
    varticalViewEndIndex    : 0,
    verticalViewSliderIndex : 0,

    gridViewlastScrollTop   : 0,
  }

  options = {
    mode            : "vertical",
    imagePerPage    : 5,
    onInitialized   : null,
    onImageClick    : null
  }
  controller  = new AbortController()
  refGrid     = React.createRef()

  
  constructor(props) {
    super(props)

    this.options.mode             = props.mode === undefined ? "vertical" : props.mode
    this.options.imagePerPage     = props.imagePerPage
    this.options.onInitialized    = props.onInitialized === undefined ? null : props.onInitialized
    this.options.onImageClick     = props.onImageClick  === undefined ? null : props.onImageClick
    this.options.album            = props.album === undefined ? null : props.album

    this.getSelectedIndex         = this.getSelectedIndex.bind(this)
    this.getTotalImageCount       = this.getTotalImageCount.bind(this)
    this.fetchImage               = this.fetchImage.bind(this)
    this.selectImage              = this.selectImage.bind(this)
    this.changeSelectOnVertical   = this.changeSelectOnVertical.bind(this)
    this.handleScroll             = this.handleScroll.bind(this)

    if (this.options.album !== undefined && this.options.album !== null) {
      this.state.authorized = false
      this.state.album = this.options.album
    } else {
      this.fetchImage(0, this.props.initialImages === undefined ? this.options.imagePerPage : this.props.initialImages)
      .then(res => {
        if(this.options.onImageClick !== null) {
          if(this.state.fetchedImages.length > 0) {
            this.state.verticalViewSliderIndex = this.state.totalImageCount-1
            this.selectImage(this.state.fetchedImages[0])
          }
  
          if(this.options.onInitialized !== null) {
            this.options.onInitialized()
          }
        }
        this.state.fetchedImageIndex += res.length
        this.setState(this.state)
      })
    }
    this.setState(this.state)
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount = () => {
      window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(event) {
    event.preventDefault()

    if(this.options.mode === "vertical") {
      this.fetchImage(this.state.verticalViewStartIndex, this.options.imagePerPage)
    } else {
      // * Mobile View 등에서 GridView 로 보여지는 경우에는, 무한 스크롤 기법을 이용한다.
      const element = this.refGrid.current
      if(element === null) {
        return
      }

      // * 아래쪽으로 스크롤하는지 여부를 감지한다
      var st = window.pageYOffset || document.documentElement.scrollTop
      const direction = st > this.state.gridViewlastScrollTop ? "down" : "up"
      this.state.gridViewlastScrollTop = st <= 0 ? 0 : st
  
      // * 맨 밑바닥까지 스크롤되었고, 아래쪽으로 스크롤하는 경우에는 이미지를 추가로 가져온다
      if(element.getBoundingClientRect().bottom <= window.innerHeight && direction === "down") {
        if(this.state.fetchedImages.length < this.state.totalImageCount && this.state.fectchingImage === false) {
          this.fetchImage(this.state.fetchedImageIndex, this.options.imagePerPage)
          .then(images => {
            this.state.fetchedImageIndex += images.length
            this.setState(this.state)
          })
        }
      }
    }
  }

  getSelectedIndex() {
    return this.state.selectedIndex
  }

  getTotalImageCount() {
    return this.state.totalImageCount
  }

  fetchImage(startIndex, requestImageCount) {
    if(this.state.fectchingImage) {
      return new Promise((resolve, reject) => { resolve([]) })
    }

    this.state.fectchingImage = true
    this.setState(this.state)

    var index = startIndex
    return fetch(`/api/v1/gallery/thumbnails?start=${startIndex}&pagePerPhotos=${requestImageCount}${ this.state.albumId ? `&album=${this.state.albumId}` : ""}`)
    .then(res => res.json())
    .catch(error => alert("서버에서 오류가 발생했습니다!"))
    .then(res => {
      this.state.totalImageCount = res.result.totalCount

      const images = []
      res.result.photos.forEach(photo => {
        const image = { id: photo.photoId, src: this.state.album === null ? `/static/gallery/${photo.photoId}` : `/static/album/${this.state.album}/gallery/${photo.photoId}`, index: index++, width: photo.width, height: photo.height, selected: null }
        if(this.state.albumId !== null) {
          image.selected = photo.selected
        }
        
        this.state.fetchedImages[image.index] = image
        images.push(image)
      })

      this.state.fectchingImage = false
      this.setState(this.state)      
      return images
    })
  }
  
  changeSelectOnVertical(modified) {
    const middleVerticalThumbnailIndex = Math.floor(this.options.imagePerPage / 2)
    const applySelectedImage = (function() {
      this.state.selectedIndex += modified
      if(this.state.selectedIndex < 0) {
        this.state.selectedIndex = 0
      }
      this.state.verticalViewSliderIndex = (this.state.totalImageCount - 1) - this.state.selectedIndex

      this.state.verticalViewStartIndex = this.state.selectedIndex - middleVerticalThumbnailIndex
      if(this.state.verticalViewStartIndex < 0) {
        this.state.verticalViewStartIndex = 0
      }

      this.selectImage(this.state.fetchedImages[this.state.selectedIndex])
      this.setState(this.state)
    }).bind(this)
    
    var future = null
    if(modified > 0) {
      future = this.fetchImage(this.state.verticalViewStartIndex+this.options.imagePerPage, modified)
      .then(() => {
        applySelectedImage()
      })
    } else {
      applySelectedImage()
    }
    return future
  }

  selectImage(image) {
    if(image === undefined) {
      return
    }

    this.state.selectedIndex = image.index
    if(this.options.onImageClick !== null) {
      this.options.onImageClick(image)
    }
    this.setState(this.state)
  }

  authorizeAlbum(event) {
    fetch(`/api/v1/authorize?type=album&album=${this.state.album}`, {
      method:'post',
      body: JSON.stringify({password: crypto.SHA256(this.state.password).toString()})
    })
    .then(res => res.json())
    .then(res => {
      if (res.result.success === true) {
        this.setState({ albumId: res.result.albumId, authorized : true })

        this.fetchImage(0, this.props.initialImages === undefined ? this.options.imagePerPage : this.props.initialImages)
        .then(res => {
          if(this.options.onImageClick !== null) {
            if(this.state.fetchedImages.length > 0) {
              this.state.verticalViewSliderIndex = this.state.totalImageCount-1
              this.selectImage(this.state.fetchedImages[0])
            }
    
            if(this.options.onInitialized !== null) {
              this.options.onInitialized()
            }
          }
          this.state.fetchedImageIndex += res.length
          this.setState(this.state)
        })
      } else {
        if(res.result.expired) {
          this.setState({ authorizeError: "이미 만료된 링크입니다. 더 이상 진행할 수 없습니다" })
        } else {
          this.setState({ authorizeError: "비밀번호가 일치하지 않습니다. 다시 시도해주세요" })
        }
      }
    })
    .catch(res => {
      this.setState({authorizeError: "서버가 정상적으로 응답하지 않습니다. 나중에 다시 시도해주세요"})
    })
  }

  sendPhotoSelected(photo) {
    const selected = !photo.selected
    fetch(`/api/v1/album?type=selectphoto&album=${this.state.albumId}&photo=${photo.id}&selected=${selected}`, {
      method:'put',
    })
    .then(res => res.json())
    .then(res => {
      if (res.result.success === true) {
        photo.selected = selected
        this.forceUpdate()
      }
    })
    .catch(res => {
    })
  }

  handleInputPassword(evt) {
    this.setState({ password: evt.target.value })
  }

  handleWheelEvent(e) {
    if(this.state.handleWheeling === true) return
    this.state.handleWheeling = true
    this.setState(this.state)

    var delta = e.deltaY / 100
    if (delta > 0) delta = 1
    else delta = -1

    var newValue = this.state.verticalViewSliderIndex - delta
    if(newValue > this.state.totalImageCount || newValue < 0) delta = 0
    
    const future = this.changeSelectOnVertical(delta)
    if(future !== null) {
      future.then(e => this.setState({ handleWheeling: false }))
    } else {
      this.setState({ handleWheeling: false })
    }
  }

  render() {
    if(this.state.authorized) {
      if(this.options.mode === "vertical") {
        return this.renderVerticalView()
      } else {
        return this.renderGridView()
      }
    } else {
      return (
        <div>
          <Dialog open={this.state.authorized === false} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">앨범 권한 인증</DialogTitle>
            <DialogContent>
              <DialogContentText>관리자로부터 전달받은 앨범 비밀번호를 입력해주세요</DialogContentText>
              <DialogContentText style={{ color: "red", fontSize: "10px" }}>{this.state.authorizeError}</DialogContentText>
              <TextField autoFocus size="small" margin="dense" label="비밀번호" type="password" variant="standard" fullWidth onChange={this.handleInputPassword.bind(this)}/>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.authorizeAlbum.bind(this)}>제출하기</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
  }

  // * PC 화면
  renderVerticalView() {
    return (
      <div style={{display: "inline-block", height: "calc(100%- 100px)",lineHeight: "calc(100% - 100px)"}}>
        <div style={{display: "inline-block", width: "calc(100% - 10px)", height: "calc(100% - 100px)"}}>
          <IconButton aria-label="delete" onClick={() => this.changeSelectOnVertical(-1)} style={{width: 40, height: 20, opacity: 0.7, visibility: (this.state.selectedIndex > 0 ? "visible" : "hidden")}}><Avatar src={upArrow} style={{width:40, height: 20}}/></IconButton>
          <div style={{height: 16}}/>

          <div style={{width: "100%", height: "100%"}}>
            <div draggable="true" onDrag={e => { e.preventDefault(); e.stopPropagation() }} onWheel={this.handleWheelEvent.bind(this)} ref={this.state.verticalViewRegionRef} style={{width: "100%", height: "100%", display: "inline-block"/*, overflow: 'scroll'*/,}}>
              {Array.from(Array(this.options.imagePerPage).keys()).map(verticalIndex => {
                const index       = verticalIndex + this.state.verticalViewStartIndex
                const image       = this.state.fetchedImages[index]
                const hasNoImage  = image === undefined
                const divKey      = hasNoImage ? String(Math.random() * 100000) : "THUMBNAIL-"+image.index
                const style       = { marginBottom: 12, width: 132, height: 132, position: "relative" }
                return (
                  <div draggable="false" style={style} key={divKey}>
                    {hasNoImage || image.selected === null ? "" : (
                      <IconButton size="medium" style={{ position: "absolute", top: 5, right: 5, zIndex: 100, backgroundColor: "white", borderRadius: "50%"}} 
                        onClick={() => this.sendPhotoSelected(image)}
                      >
                        <CheckCircleIcon style={{ color: image.selected ? green[500] : green[50]}} fontSize="inherit" />
                      </IconButton>
                    )}
                    {hasNoImage ? "" : (<img draggable="false" src={this.state.album === null ? `/static/thumbnails/${image.id}` : `/static/album/${this.state.album}/thumbnail/${image.id}`} onClick={() => this.changeSelectOnVertical(image.index - this.state.selectedIndex)} style={{width: "100%", height: "100%"}}/>)}
                    {(hasNoImage || this.state.selectedIndex !== image.index) ? "" : (<div style={{ border: "2px solid red", width: "calc(100% - 4px)", height: "calc(100% - 4px)", position: "absolute", top: 0, left: 0, zIndex: 9}} />)}
                  </div>
                )})
              }
            </div>
          </div>        
          <div style={{height: 16}}/>
          <IconButton aria-label="delete" onClick={() => this.changeSelectOnVertical(+1)} style={{width: 40, height: 20, opacity: 0.7, visibility: (this.state.verticalViewStartIndex + Math.floor(this.options.imagePerPage / 2) + 1 < this.state.totalImageCount ? "visible" : "hidden")}}><Avatar src={downArrow} style={{width:40, height: 20}}/></IconButton>
        </div>
        <div style={{display: "inline-block", height: "100%"}} >
          <Slider orientation="vertical" value={this.state.verticalViewSliderIndex} min={0} max={this.state.totalImageCount-1}
            style={{padding: 4, height: 696, transform: "translateY(-47px)"}}
            track={"inverted"}
            onChange={(evt, newValue) => { 
              this.state.verticalViewSliderIndex = newValue
              this.setState(this.state)
            }}
            onChangeCommitted={(evt, newValue) => {
              this.state.verticalViewSliderIndex = newValue
              this.changeSelectOnVertical((this.state.totalImageCount - newValue -1) - this.state.selectedIndex)
            }}
            onWheel={this.handleWheelEvent.bind(this)}
            valueLabelFormat={(value) => (this.state.totalImageCount - value)}
          />
        </div>
      </div>
    )
  }

  // * 모바일 화면
  renderGridView() {
    return (
      <ImageList ref={this.refGrid} cols={3} spacing={10} id="images" style={{ margin: "auto" }}> 
        {this.state.fetchedImages.map(image => (
          <ImageListItem key={image.id} cols={1} style={{width: "calc(100% - 3px)", height: "calc(100% - 3px)" }}>
            {image.selected === null ? "" : (
              <IconButton size="medium" style={{ position: "absolute", top: 5, right: 5, zIndex: 100, backgroundColor: "white", borderRadius: "50%"}} 
                onClick={() => this.sendPhotoSelected(image)}
              >
                <CheckCircleIcon style={{ color: image.selected ? green[500] : green[50]}} fontSize="inherit" />
              </IconButton>
            )}
            <img draggable="false" src={this.state.album === null ? `/static/thumbnails/${image.id}` : `/static/album/${this.state.album}/thumbnail/${image.id}`} style={{border: "1px solid #ffffff", width: "100%", height: "100%"}} onClick={() => { if(this.options.onImageClick !== null) { this.options.onImageClick(image) }}} />
          </ImageListItem>
        ))}
      </ImageList>
    )
  }
}
