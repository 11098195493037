import React from "react";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Avatar from '@material-ui/core/Avatar';
import Alert from '@material-ui/core/Alert';
import Slide from '@material-ui/core/Slide';
// Icon
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DownloadIcon from '@material-ui/icons/GetApp';
// Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// ImageList
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ListSubheader from '@material-ui/core/ListSubheader';
// Cropper
import 'cropperjs-react';
import Cropper from "react-cropper";
import NoPhoto from "static/noPhoto.gif"
// Backdrop
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// Table
import PhotoTable from "component/table/Table";
// Clipboard
import {CopyToClipboard} from 'react-copy-to-clipboard';
import crypto from 'crypto-js';
import { green } from '@material-ui/core/colors';


function formatDate(time) {
  const date  = new Date(time)
  const pad   = function(time) { return time < 10 ? '0'+time : time }
  return `${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
}

function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
}

function donwloadFile(blob, filename) {
    var url = window.URL.createObjectURL(blob)
    var a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    a.remove()
}

const slideTransition = (props) => {
  return <Slide {...props} direction="up" />
}


function AlbumDetail(props) {
  const albumInfo = props.album
  const onComplete = props.onComplete
  const onCancle = props.onCancle
  const onProgress = props.onProgress
  const expired = albumInfo && albumInfo.expired
  const [expand, setExpand] = React.useState(false)
  const [modify, setModify] = React.useState(false)
  const [photos, setPhotos] = React.useState([])
  const [deletedPhotos, setDeletedPhotos] = React.useState([])
  const [addedPhotos, setAddedPhotos] = React.useState([])
  const [uploadImage, setUploadImage] = React.useState(null)
  const [cropper, setCropper] = React.useState(null)
  const cropperRef = React.createRef()
  const photoSelectRef = React.createRef()

  const onUploadImageSelected = function(event) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0]
  
      let reader = new FileReader()
      reader.onloadend = () => {
        var img     = new Image()
        img.onload  = () => { setUploadImage({ src : reader.result, width : img.width, height : img.height, photoId: null, file : file, createtime: file.lastModified }) }
        img.src     = reader.result
      }
      reader.readAsDataURL(event.target.files[0])
    } else {
      setUploadImage(null)
    }
  }

  const addImage = function() {
    if (typeof cropper !== "undefined" && cropper !== null) {
      uploadImage.thumbnail = cropper.getCroppedCanvas({ width: 300, height: 300, imageSmoothingQuality: 'high' }).toDataURL()
      setPhotos(photos.concat(uploadImage))
      setAddedPhotos(addedPhotos.concat(uploadImage))
      setUploadImage(null)
    }
  }

  const deleteImage = function(image) {
    setPhotos(photos.filter(function(e) { return e !== image }))
    setAddedPhotos(addedPhotos.filter(function(e) { return e !== image}))
    if(image.photoId !== null) {
      setDeletedPhotos(deletedPhotos.concat(image.photoId))
    }
  }

  const sendModifyAlbum = function() {
    if(onProgress) onProgress()

    const data = new FormData()
    Array.from(Array(addedPhotos.length).keys()).forEach(index => {
      const albumImage = addedPhotos[index]
      data.append(`photo_${index}`, albumImage.file)
      data.append(`photometa_${index}`, JSON.stringify({
        id : albumImage.id,
        createtime : albumImage.createtime,
        width: albumImage.width,
        height: albumImage.height
      }))
      data.append(`thumbnail_${index}`, dataURItoBlob(albumImage.thumbnail))
    })
    data.append("tags", "")
    data.append("model", albumInfo.modelId)

    fetch(`/api/v1/album?album=${albumInfo.id}&privateKey=${albumInfo.privateKey}&model=${albumInfo.modelId}&photoCount=${addedPhotos.length}&deletedPhotos=${deletedPhotos}`,
      { method: 'PUT', body: data, })
    .then(res => res.json())
    .catch(error => { alert("서버에서 오류가 발생하였습니다"); return null})
    .then(res => {
      setModify(false)
      if (onComplete) onComplete()
    })
  }
  
  React.useEffect(() => {
    setExpand(albumInfo !== undefined && albumInfo !== null)
    if(albumInfo !== undefined && albumInfo !== null) setPhotos(albumInfo.photos !== null ? albumInfo.photos : [])
    setModify(false)
  }, [albumInfo])

  return (
    <Accordion square expanded={expand} style={{
        margin: "10px 0px", border: '1px solid rgba(0, 0, 0, .125)', boxShadow: 'none',
        '&:not(:lastChild)': { borderBottom: 0, }, '&:before': { display: 'none', }, '&$expanded': { margin: 'auto', },
      }}
      onChange={() => {
        if(expand) setExpand(false);
        else if(albumInfo !== undefined && albumInfo !== null) setExpand(true)
      }}
    >
      <AccordionSummary style={{ width: "100%", backgroundColor: 'rgba(0, 0, 0, .03)', borderBottom: '1px solid rgba(0, 0, 0, .125)', marginBottom: -1, minHeight: 56, '&$expanded': { minHeight: 56, },}}>
        <Typography style={{ width: "100%" }}>&nbsp;&nbsp;앨범 조회/수정</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 2, width: "100%" }}>
        <div style={{textAlign: "center", marginBottom: 15, width: "100%"}}>
          <div>
            {!albumInfo ? "" : <Typography variant="h5">앨범 {albumInfo.id} ({albumInfo.privateKey})</Typography>}
            {!albumInfo ? "" : (
              <div style={{fontSize: 0}}>
                <Avatar sx={{ width: 64, height: 64 }} src={`/static/model/${albumInfo.modelPhotoId}`} style={{ verticalAlign: "top", display: "inline-block" }} />
                <div style={{ verticalAlign: "top", display: "inline-block", height: 64, lineHeight: "64px", fontSize: 20, marginLeft: 5 }}>{albumInfo.modelName}</div>
              </div>
            )}
          </div>
          {!expired ? "" : (<Alert severity="error">이 앨범은 만료되어, 이미지의 다운로드만 가능합니다</Alert>)}
          <div>
            <div style={{ display: modify ? "block" : "none" }}>
              <Typography>업로드할 사진을 선택해주세요</Typography>
              <div style={{display: "inline-block", textAlign: "center", margin: "10px 0px"}}>
                {uploadImage === null ?
                  <div style={{width: 300, height: 300, border: "2px solid grey", cursor: "pointer"}}>
                    <img alt="noModifyImg" src={NoPhoto} style={{width: 150, height: 150, transform: "translateY(50%)"}} onClick={() => { photoSelectRef.current.click() }}/>
                  </div> : 
                  <Cropper src={uploadImage.src} style={{ height: 300, width: 300 }} ref={cropperRef}
                    initialAspectRatio={1} aspectRatio={1} guides={true} background={false} responsive={true} checkOrientation={false} zoomable={false} 
                    autoCropArea={1} cropBoxMovable={false} cropBoxResizable={false} scalable={true} minCropBoxHeight={150} minCropBoxWidth={150} minContainerWidth={150} minContainerHeight={150}
                    viewMode={1} dragMode="move" onInitialized={(instance) => { setCropper(instance); instance.setCropBoxData({width: 300, hegiht: 300}) }}
                  />
                }
                <input type="file" name="photo" ref={photoSelectRef} required onChange={onUploadImageSelected} style={{display: "none"}} />
                <Button variant="contained" style={{marginTop: 10}} onClick={() => photoSelectRef.current.click()}>사진 선택</Button>
                &nbsp;&nbsp;
                <Button variant="contained" style={{marginTop: 10}} disabled={uploadImage === null} onClick={addImage}>사진 추가</Button>
              </div>
            </div>
            <ImageList cols={10}>
              <ImageListItem key="Subheader" style={{ height: 'auto', }}>
                <ListSubheader component="div">사진</ListSubheader>
              </ImageListItem>
              {photos.map(image =>                
                <ImageListItem key={image.thumbnail}>
                  <CheckCircleIcon style={{ position: "absolute", right: 5, top: 5, color: image.selected ? green[500] : green[50], backgroundColor: "white", borderRadius: "50%" }}/>
                  {modify ? "" : (
                    <DownloadIcon color="primary" style={{ position: "absolute", left: 5, bottom: 5, backgroundColor: "white", borderRadius: "50%", cursor: "pointer" }}
                      onClick={() => {
                        fetch(`/api/v1/downloadalbum?album=${albumInfo.id}&password=${crypto.SHA256(albumInfo.password)}&photo=${image.photoId}`)
                        .then(res => res.blob())
                        .then(blob => donwloadFile(blob, `${image.photoId}.png`))
                      }}
                    />
                  )}
                  <img alt={image.photoId} src={image.thumbnail} />
                  <ImageListItemBar style={{ display: modify ? "inherit" : "none" }} actionIcon={
                    <IconButton style={{marginRight: 5}} onClick={() => deleteImage(image)}>
                      <ClearIcon color="secondary" />
                    </IconButton>
                  }/>
                </ImageListItem>
              )}
            </ImageList>
          </div>
          {!modify ? (
            <>
              <Button variant="contained" onClick={() => {
                fetch(`/api/v1/downloadalbum?album=${albumInfo.id}&password=${crypto.SHA256(albumInfo.password)}`)
                .then(res => res.status === 200 ? res.blob() : null)
                .catch(error => { alert("서버에서 오류가 발생하였습니다"); return null})
                .then(blob => blob !== null ? donwloadFile(blob, 'album.zip') : "")
              }}>전체 다운로드</Button>
              &nbsp;
              <Button variant="contained" onClick={() => {
                fetch(`/api/v1/downloadalbum?album=${albumInfo.id}&password=${crypto.SHA256(albumInfo.password)}&selected=1`)
                .then(res => res.status === 200 ? res.blob() : null)
                .catch(error => { alert("서버에서 오류가 발생하였습니다"); return null})
                .then(blob => blob !== null ? donwloadFile(blob, 'album.zip') : "")
              }}>선택된 이미지 다운로드</Button>
            </>
          ) : ""}
          {!modify ? <>&nbsp;</> : ""}
          {modify ? (<><Button variant="contained" color="primary" onClick={evt => sendModifyAlbum(evt)}>수정 완료</Button>&nbsp;</>) : ""}
          {(!modify && !expired) ? (<><Button variant="contained" color="primary" onClick={evt => setModify(true)}>수정 하기</Button>&nbsp;</>) : ""}
          {modify ?
            <Button variant="contained" color="secondary" onClick={() => setModify(false)}>수정 취소</Button> :
            <Button variant="contained" color="secondary" onClick={() => { if (onCancle) onCancle(albumInfo) }}>그만 보기</Button>
          }
        </div>
      </AccordionDetails>
    </Accordion>
  )
}


function AlbumCreate(props) {
  const albumInfo = props.album
  const onComplete = props.onComplete
  const onProgress = props.onProgress
  const [expand, setExpand] = React.useState(false)
  const [step, setStep] = React.useState(0)
  const [stepProcessable, setStepProcessable] = React.useState(false)
  const [photos, setPhotos] = React.useState([])
  const [modelCandidates, setModelCandidates] = React.useState([])
  const [modelId, setModelId] = React.useState(null)
  const [modelName, setModelName] = React.useState("")  
  const [uploadImage, setUploadImage] = React.useState(null)
  const [cropper, setCropper] = React.useState(null)
  const cropperRef = React.createRef()
  const photoSelectRef = React.createRef()

  const onUploadImageSelected = function(event) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0]
  
      let reader = new FileReader()
      reader.onloadend = () => {
        var img = new Image()
        img.onload  = () => { setUploadImage({ src : reader.result, width : img.width, height : img.height, photoId: null, file : file, createtime: file.lastModified }) }
        img.src = reader.result
      }
      reader.readAsDataURL(event.target.files[0])
    } else {
      setUploadImage(null)
    }
  }

  const addImage = function() {
    if (typeof cropper !== "undefined" && cropper !== null) {
      uploadImage.thumbnail = cropper.getCroppedCanvas({ width: 300, height: 300, imageSmoothingQuality: 'high' }).toDataURL()
      setPhotos(photos.concat(uploadImage))
      setUploadImage(null)
      setStepProcessable(true)
    }
  }

  const deleteImage = function(image) {
    const filtered = photos.filter(function(e) { return e !== image })
    setPhotos(filtered)
    setStepProcessable(filtered.length > 0)
  }

  const sendCreateAlbumRequest = function() {
    if(onProgress) onProgress()

    const data = new FormData()
    Array.from(Array(photos.length).keys()).forEach(index => {
      const photo = photos[index]
      data.append(`photo_${index}`, photo.file)
      data.append(`photometa_${index}`, JSON.stringify({
        id : photo.id,
        model_id : modelId,
        createtime : photo.file.lastModified,
        width: photo.width,
        height: photo.height
      }))
      data.append(`thumbnail_${index}`, dataURItoBlob(photo.thumbnail))
    })
    data.append("tags", "")
    data.append("model", modelId)

    fetch(`/api/v1/album?photoCount=${photos.length}`, { method: 'POST', body: data, })
    .catch(error => { alert("서버에서 오류가 발생하였습니다"); return null})
    .then(res => {
      setExpand(null)
      setUploadImage(null)
      setPhotos([])
      setModelId(null)
      setModelName("")
      setStep(0)
      setStepProcessable(false)

      if(onComplete) onComplete()
    })
  }

  const stepSelectModel = {
    label: "모델 선택",
    nextStepReady: function() { return modelId && modelName },
    tag: (
      <div>
        <Typography>대상 모델을 입력해주세요</Typography>
        <Autocomplete style={{margin: "10px 20%",}} options={modelCandidates} autoHighlight getOptionLabel={option => option.name} inputValue={modelName}
          renderOption={option => (<React.Fragment><img alt={option.photoId} src={`/static/model/${option.photoId}`} style={{ width: 30, height: 30 }} />&nbsp;{option.name}</React.Fragment>)}
          onOpen={() => {
            if(modelCandidates.length === 0) {
              fetch(`/api/v1/model?start=${0}&pagePerPhotos=${10}&type=name&name=`)
              .then(res => res.json())
              .catch(error => { alert("서버에서 오류가 발생하였습니다"); return null})
              .then(res => res !== null ? setModelCandidates(res.result.models) : "")
            }
          }}
          onChangeCapture={(event) => setModelName(event.target.value)}
          onChange={(_, newValue) => {
            if(newValue === null) {
              setModelId(null)
              setModelName("")
              setStepProcessable(false)
            } else {
              setModelId(newValue.id)
              setModelName(newValue.name)
              setStepProcessable(true)
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="모델 이름을 입력하세요" variant="outlined" inputProps={{...params.inputProps, autoComplete: 'new-password', }}
              onChange={event => {
                fetch(`/api/v1/model?start=${0}&pagePerPhotos=${10}&type=name&name=${event.target.value}`).then(res => res.json()).then(res => setModelCandidates(res.result.models))
              }}
            />
          )}
        />
      </div>
    )
  }

  const stepSelectPhotos = {
    label : "사진 선택",
    nextStepReady: function() { return photos.length > 0 },
    tag: (
      <div>
        <Typography>업로드할 사진을 선택해주세요</Typography>
        <div style={{display: "inline-block", textAlign: "center", margin: "10px 0px"}}>
          {uploadImage === null ?
            <div style={{width: 300, height: 300, border: "2px solid grey", cursor: "pointer"}}>
              <img alt="addNoImg" src={NoPhoto} style={{width: 150, height: 150, transform: "translateY(50%)"}} onClick={() => { photoSelectRef.current.click() }}/>
            </div> : 
            <Cropper src={uploadImage.src} style={{ height: 300, width: 300 }} ref={cropperRef}
              initialAspectRatio={1} aspectRatio={1} guides={true} background={false} responsive={true} checkOrientation={false} zoomable={false} 
              autoCropArea={1} cropBoxMovable={false} cropBoxResizable={false} scalable={true} minCropBoxHeight={150} minCropBoxWidth={150} minContainerWidth={150} minContainerHeight={150}
              viewMode={1} dragMode="move" onInitialized={(instance) => { setCropper(instance); instance.setCropBoxData({width: 300, hegiht: 300}) }}
            />
          }
          <input type="file" name="photo" ref={photoSelectRef} required onChange={onUploadImageSelected} style={{display: "none"}} />
          <Button variant="contained" style={{marginTop: 10}} onClick={() => photoSelectRef.current.click() }>사진 선택</Button>
          &nbsp;&nbsp;
          <Button variant="contained" style={{marginTop: 10}} disabled={uploadImage === null} onClick={addImage}>사진 추가</Button>
        </div>
        <ImageList cols={10}>
          <ImageListItem key="Subheader" style={{ height: 'auto', }}>
            <ListSubheader component="div">추가된<br/>사진</ListSubheader>
          </ImageListItem>
          {photos.map(image => 
            <ImageListItem key={image.file.name}>
              <img alt={image.file.name} src={image.thumbnail} />
              <ImageListItemBar actionIcon={<IconButton style={{marginRight: 10}} onClick={() => deleteImage(image)}><ClearIcon color="secondary" /></IconButton>}/>
            </ImageListItem>
          )}
        </ImageList>
      </div>
    )
  }

  const stepUploadAlbums = {
    label : "업로드",
    nextStepReady: function() { return photos.length > 0 },
    tag: (
      <div>
        <Typography>최종 업로드할 내용을 확인해주세요</Typography>
        <ImageList cols={10}>
          <ImageListItem key="Subheader" style={{ height: 'auto', }}><ListSubheader component="div">추가된<br/>사진</ListSubheader></ImageListItem>
          {photos.map(image => <ImageListItem key={`UPLOAD-${image.file.filename}`}><img alt={image.file.filename} src={image.thumbnail} /></ImageListItem>)}
        </ImageList>
        <div style={{display: "inline-block", textAlign: "center"}}>모델 : <b>{modelName}</b></div>
        <Typography>위와 같이 업로드하시려면 '완료'를 클릭하세요</Typography>
        <br/>
      </div>
    )
  }

  const steps = [stepSelectModel, stepSelectPhotos, stepUploadAlbums]  
  return (
    <Accordion square expanded={expand} style={{ margin: "10px 0px", border: '1px solid rgba(0, 0, 0, .125)', boxShadow: 'none',
        '&:not(:lastChild)': { borderBottom: 0, }, '&:before': { display: 'none', }, '&$expanded': { margin: 'auto', }, }}
      onChange={() => setExpand(!expand)}
    >
      <AccordionSummary style={{ width: "100%", backgroundColor: 'rgba(0, 0, 0, .03)', borderBottom: '1px solid rgba(0, 0, 0, .125)', marginBottom: -1, minHeight: 56, '&$expanded': { minHeight: 56, },}}>
        <Typography style={{ width: "100%" }}>&nbsp;&nbsp;앨범 생성</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 2, width: "100%" }}>
        <Stepper activeStep={step} alternativeLabel>{steps.map(stepInstance => (<Step key={stepInstance.label}><StepLabel>{stepInstance.label}</StepLabel></Step>))}</Stepper>
        <div style={{textAlign: "center", marginBottom: 15, width: "100%"}}>
          <div>{!albumInfo ? "" : (<><img alt={albumInfo.modelName} src={`/static/model/${albumInfo.modelPhotoId}`} style={{ width: 100, height: 100 }}/><br/>{albumInfo.modelName}</>)}</div>
          <div>{steps[step].tag}</div>
          <Button disabled={step === 0} variant="contained" color="secondary" onClick={() => setStep(step-1)}>이전</Button>
          <div style={{ display: "inline-block", width: 10 }}>&nbsp;</div>
          <Button disabled={!stepProcessable} variant="contained" color="primary"
            onClick={() => {
              const nextStep = (step+1) % steps.length
              if(nextStep > 0) {
                setStep(nextStep)
                setStepProcessable(steps[nextStep].nextStepReady())
              } else {
                sendCreateAlbumRequest()
              }
          }}>
            {step === steps.length - 1 ? '완료' : '다음'}
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default class AlbumAdmin extends React.Component {
  state = {
    progress          : false,
    fetchedImages     : [],
    selectedImage     : [],
    lastUpdateTime    : Date.now(),
    pagePerCount      : 10,
    // * Fetch operation related variables.
    fetchCondition    : { model : "" },
    // * Modification realted variables.
    currentAlbumInfo  : null,
    // * Delete operation related variables.
    deleteIds         : [],
    openDeleteDialog  : false,
  }

  columns = [
    { field: 'id',          headerName: 'ID',           width: 10  },
    { field: 'model',       headerName: '모델',         width: 100, sortable: true, },
    { field: 'startTime',   headerName: '생성시간',     width: 100 },
    { field: 'expireTime',  headerName: '만료시간',     width: 100 },
    { field: 'link',        headerName: '앨범키',       width: 100 },
    { field: 'password',    headerName: '비밀번호',     width: 100 },
    { field: 'modify',      headerName: '조회/수정',    width: 10  },
    { field: 'delete',      headerName: '삭제',         width: 10  },
  ]

  constructor(props) {
    super(props)

    this.deleteAlbums     = this.deleteAlbums.bind(this)
    this.onDelete         = this.onDelete.bind(this)
  }

  fetchImage(currentPage, requestImageCount) {
    this.setState({ selectedImage : [] })

    const startIndex = currentPage * requestImageCount
    return fetch(`/api/v1/album?start=${startIndex}&pagePerPhotos=${requestImageCount}&model=${this.state.fetchCondition.model}`)
    .then(res => res.json())
    .catch(error => { alert("서버에서 오류가 발생하였습니다"); return null})
    .then(res => {
      if (res === null) return { total: 0, rows: [] }

      const albums = []
      res.result.albums.forEach(album => {
        const expired = new Date(album.expireTime).getTime() <= Date.now()
        albums.push({
          id          : album.id,
          thumbnail   : (<img alt={album.photoId} src={`/static/thumbnails/${album.photoId}`} style={{width: 80, height: 80}} />),
          model       : <div style={{fontSize: 0}}><Avatar sx={{ width: 32, height: 32 }} src={`/static/model/${album.modelPhotoId}`} style={{ verticalAlign: "top", display: "inline-block" }} /><div style={{ verticalAlign: "top", display: "inline-block", height: 32, lineHeight: "32px", fontSize: 14, marginLeft: 5 }}>{album.modelName}</div></div>,
          startTime   : formatDate(album.startTime),
          expireTime  : formatDate(album.expireTime),
          link        : (expired ? 
            <>
              만료됨<br/>
              <Button variant="contained" onClick={() => this.reactivateAlbum(album)}>재활성화</Button>
            </>
            : 
            <>
              {album.privateKey}<br/>
              <CopyToClipboard text={`http://by-stealth.com?album=${album.privateKey}`} onCopy={() => this.setState({copied: true})} style={{display: "inline-block"}}>
                <Button variant="contained" color="primary">링크복사</Button>
              </CopyToClipboard>&nbsp;<Button variant="contained" color="secondary" onClick={() => this.expireAlbum(album)}>강제만료</Button>
            </>
          ),
          password    : (expired ? <Button variant="contained" disabled>만료됨</Button> : (<CopyToClipboard text={album.password} onCopy={() => this.setState({copied: true})}><div>{album.password}<br/><Button variant="contained" color="primary">복사</Button></div></CopyToClipboard>)),
          modify      : (<Button variant="contained" color="primary"   onClick={() => { this.modifyAlbum(album) }}>보기</Button>),
          delete      : (<Button variant="contained" color="secondary" onClick={() => { this.setState({openDeleteDialog: true, deleteIds: [album.id]}) }}>삭제</Button>)
        })
      })
      return { total: res.result.totalCount, rows: albums }
    })
  }

  expireAlbum(album) {
    this.setState({ progress: true })
      
    // * 앨범을 수정할 수 있도록 정보를 설정합니다.
    fetch(`/api/v1/album?type=expire&album=${album.id}`, { method: 'put' })
    .then(res => res.json())
    .catch(error => { alert("서버에서 오류가 발생하였습니다"); return null})
    .then(res => {
      this.setState({ lastUpdateTime: Date.now(), progress: false })
    })
  }

  reactivateAlbum(album) {
    this.setState({ progress: true })

    // * 앨범을 수정할 수 있도록 정보를 설정합니다.
    fetch(`/api/v1/album?type=activate&album=${album.id}`, { method: 'put' })
    .then(res => res.json())
    .catch(error => { alert("서버에서 오류가 발생하였습니다"); return null})
    .then(res => {
      this.setState({ lastUpdateTime: Date.now(), progress: false })
    })
  }
  
  modifyAlbum(album) {
    this.setState({ progress: true })

    // * 앨범을 수정할 수 있도록 정보를 설정합니다.
    fetch(`/api/v1/album?start=${0}&pagePerPhotos=${65536}&privateKey=${album.privateKey}&password=${crypto.SHA256(album.password)}`)
    .then(res => res.json())
    .catch(error => { alert("서버에서 오류가 발생하였습니다"); return null})
    .then(res => {
      if(res === null) {
        this.setState({ currentAlbumInfo: null, progress: false })
        return
      }

      const photos = res.result.photos.map(photo => { return {
        photoId: photo.photoId,
        thumbnail: `/static/album/${res.result.albums[0].privateKey}/thumbnail/${photo.photoId}`,
        selected: photo.selected
      }})

      const fetchedAlbum = res.result.albums[0]
      this.setState({
        currentAlbumInfo : {...fetchedAlbum,
          photos: photos,
          expired: res.result.expired,
        },
        progress: false
      })
    })
  }

  deleteAlbums(albumes) {
    if(albumes.length > 0) {
      this.setState({ progress: true })

      fetch(`/api/v1/album?album=${albumes}`, { method: 'delete' })
      .then(res => res.json())
      .catch(error => { alert("서버에서 오류가 발생하였습니다"); return null})
      .then(res => {
        this.setState({lastUpdateTime : Date.now()})
      })
      this.setState({deleteIds: [], currentAlbumInfo : null, progress: false})
    }
  }

  onDelete() {
    this.setState({openDeleteDialog : true, selectedImage: [], deleteIds: this.state.selectedImage.map(album => album.id)})
  }

  onPhotoSelect(selected) {
    this.setState({selectedImage: selected})
  }

  closeDialog() {
    this.setState({openDeleteDialog: false})
  }

  render() {
    const rows = this.state.fetchedImages
    return (
      <div style={{ height: "100%" }}>
        <Backdrop open={this.state.progress} style={{zIndex: 1000}}><CircularProgress color="inherit" /></Backdrop>

        <AlbumCreate onComplete={() => this.setState({lastUpdateTime: Date.now(), progress: false}) }  onProgress={() => this.setState({ progress: true })}/>

        <AlbumDetail album={this.state.currentAlbumInfo}  onProgress={() => this.setState({ progress: true })} 
          onComplete={() => this.setState({currentAlbumInfo: null, progress: false})} onCancle={() => this.setState({currentAlbumInfo : null})} />
        
        <div style={{position: "absolute", display: "inline-block", fontSize: 0 }}>
          <TextField style={{verticalAlign: "top"}} size="small" variant="outlined" label={"모델 이름으로 검색"} onChange={(event) => { this.setState({ fetchCondition: { model: event.target.value } }) }}/>
          <IconButton onClick={() => { this.setState({ lastUpdateTime: Date.now() }) }} style={{verticalAlign: "top", height: 40, marginLeft: 5}}>
            <SearchIcon />
          </IconButton>
        </div>
        <div style={{position: "absolute", right: 30, display: "inline-block" }}>
          <Button variant="contained" color="secondary" disabled={this.state.selectedImage.length === 0} onClick={this.onDelete.bind(this)}>모두 삭제</Button>
        </div>

        <div style={{width: "100%", textAlign: "center", display: "inline-block", marginTop: 50}}>
          <PhotoTable rows={rows} columns={this.columns}
            lastUpdateTime={this.state.lastUpdateTime}
            pagePerCount={this.state.pagePerCount}
            checkboxSelection
            onSelected={this.onPhotoSelect.bind(this)}
            fetchPageData={this.fetchImage.bind(this)}
            style={{display: "inline-block"}}
          />
        </div>
        
        <Dialog open={this.state.openDeleteDialog} onClose={this.closeDialog.bind(this)}>
          <DialogTitle>{"데이터 삭제하기"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              선택하신 사진이 완전히 삭제되며, 다시는 복구할 수 없습니다<br/>
              정말로 삭제하시겠습니까?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog.bind(this)} color="primary">
              그만두기
            </Button>
            <Button onClick={() => {this.deleteAlbums(this.state.deleteIds); this.closeDialog()}} color="secondary">
              삭제하기
            </Button>
          </DialogActions>
        </Dialog>
        
        <Dialog open={this.state.errorDialog} onClose={() => this.setState({ errorDialog: false })}>
          <DialogTitle>{"오류"}</DialogTitle>
          <DialogContent><DialogContentText>서버에서 오류가 발생했습니다<br/></DialogContentText></DialogContent>
          <DialogActions><Button onClick={() => this.setState({ errorDialog: false })} color="primary">확인</Button></DialogActions>
        </Dialog>

        <Snackbar open={this.state.copied} autoHideDuration={500} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} TransitionComponent={slideTransition}
          onClose={() => this.setState({copied: false})}
        >
          <Alert variant="outlined" elevation={6} onClose={() => this.setState({copied: false})} severity="success" sx={{ width: '100%' }} action={<></>}>
            복사되었습니다
          </Alert>
        </Snackbar>
      </div>
    )
  }
}
