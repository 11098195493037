import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Layout from 'layout/Layout.js'
import Admin from 'layout/admin/Admin.js'
import './index.css';

const hist = createBrowserHistory()

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/" component={Layout} />
      <Route path="/admin"  component={Admin} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
