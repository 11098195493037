import React from "react";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import routes from "layout/admin/routes.js";
import crypto from 'crypto-js';
// Fonts
import "layout/resource/fonts/fonts.css"
import styled from "styled-components";

const StyledA = styled.a`
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    color: white;
  }
`;

const hist = createBrowserHistory()
const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      const path = prop.path_param ? prop.layout + prop.path_param : prop.layout + prop.path
      if (prop.layout === "/admin") {
        return (
          <Route path={path} component={prop.component} key={`ROUTE-${path}`} />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/photo" />
  </Switch>
)

export default function Admin(props) {
  const [authorized, setAuthorized] = React.useState(false)
  const [authorizeError, setAuthorizeError] = React.useState("")
  const [password, setPassword] = React.useState("")

  const sendLogin = function() {
    const data = new FormData()
    data.append("password", crypto.SHA256(password))

    fetch(`/api/v1/admin`, { method: 'post', body: data })
    .then(res => res.json())
    .then(res => {
      setAuthorized(res.result.authorized)
      if(!res.result.authorized) {
        setAuthorizeError("비밀번호가 일치하지 않습니다!")
      }
    })
  }

  const authorize = (
    <div>
      <Dialog open={!authorized} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">관리자 인증</DialogTitle>
        <DialogContent>
          <DialogContentText>관리자 비밀번호를 입력해주세요</DialogContentText>
          <DialogContentText style={{ color: "red", fontSize: "10px" }}>{authorizeError}</DialogContentText>
          <TextField autoFocus size="small" margin="dense" label="비밀번호" type="password" variant="standard" fullWidth onChange={(event) => setPassword(event.target.value)}/>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={sendLogin}>제출하기</Button>
        </DialogActions>
      </Dialog>
    </div>
  )

  if(!authorized) {
    sendLogin()
  }

  return (
    !authorized ? authorize :
    (
      <div style={{ height: "100%"}}>
        <div style={{ width: 300, display: "inline-block", backgroundColor: "black", color: "white", height: "100vh", verticalAlign: "top", position: "fixed"}}>
          <div style={{ width: 300, height: 100, marginTop: 30, textAlign: "center", fontWeight: 700, fontSize: 27 }}>Admin</div>
          {routes.map(route => {
            const path = route.path_param ? route.layout + route.path_param : route.layout + route.path
            if (route.layout === "/admin") {
              return (
                <div key={path} style={{ marginLeft: 20, height: 50, fontSize: 0 }}>
                  <StyledA href={path} style={{ lineHeight: "32px", verticalAlign: "top", display: "inline-block", fontSize: 15 }}><Icon style={{verticalAlign: "top", display: "inline-block"}}>{route.icon}</Icon> {route.name}</StyledA>
                </div>
              );
            }
            return null
          })}
        </div>
        <div style={{display: "inline-block", width: "calc(100% - 320px)", marginLeft: 310}}>
          <Router history={hist}>
            {switchRoutes}
          </Router>
        </div>
      </div>
    )
  )
}
