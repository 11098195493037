const layoutMobileStyle = {
  layoutRoot: {
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)"
  },
  
  logo: {
    height: 60
  },

  thunmbnail: {
    width: 188,
    height: 188,
    lineHeight: "188px"
  },

  copyrightMessage: {
    verticalAlign: "top",
    color: "black",
    fontFamily: "Noto Sans KR",
    fontSize: "15px"
  },
}
export default layoutMobileStyle;