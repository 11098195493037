import React, { useState } from "react";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
// @material-ui/core dialog componetns
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// Images
import logo from "layout/resource/image/logo.png";
import leftArrow from "layout/resource/image/pc_left.png";
import rightArrow from "layout/resource/image/pc_right.png";
import instagram from "layout/resource/image/pc_instagram.png";
import mail from "layout/resource/image/pc_mail.png";
import styles from "layout/layoutPCStyle.js";
// 3rd-party
import QueryString from "qs";
// Fonts
import "layout/resource/fonts/fonts.css"
import "layout/layoutPC.css"
// ImageSet
import ImageSet from "component/thumbnail/ImageSet.js"

class LayoutPC extends React.Component {
  state = {
    imageModalShow      : false,
    image               : false,
    imageRef            : React.createRef(),
    imageExpandRef      : React.createRef(),
    imageSetRef         : React.createRef(),
    imageModalImageRef  : React.createRef(),
  }


  constructor(props) {
    super(props)
    
    this.resizeListener = this.resizeListener.bind(this)
    this.queryString    = QueryString.parse(window.location.search.substr(1))

    if ('album' in this.queryString) {

    }

    console.log(this.queryString)
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeListener)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener)
  }

  resizeListener() {
    if(this.state.imageModalImageRef.current !== null) {
      const width   = window.innerWidth
      const height  = window.innerHeight

      this.state.imageModalImageRef.current.style.maxWidth   = (width-50) + "px"
      this.state.imageModalImageRef.current.style.maxHeight  = (height-50) + "px"
    }
  }

  changeImage(modified) {
    if(this.state.imageSetRef.current !== null) {
      this.state.imageSetRef.current.changeSelectOnVertical(modified)
    }
  }

  handleOpenModalShowOpen() {
    this.state.imageModalShow = true
    this.setState(this.state)
  }
  
  handleOpenModalShowClose() {
    this.state.imageModalShow = false
    this.setState(this.state)
  }

  isLeftImageExist(image) {
    return this.state.imageSetRef.current !== null && image.index > 0
  }
  
  isRightImageExist(image) {
    return this.state.imageSetRef.current !== null && image.index+1 < this.state.imageSetRef.current.getTotalImageCount()
  }

  render() {
    const {classes} = this.props;

    const imageModal = (this.state.image === undefined || this.state.image === null) ? "" : (
      <div>
        <Dialog open={this.state.imageModalShow} onClose={() => this.handleOpenModalShowClose()} fullScreen={true}
          style={{display: "table-cell", verticalAlign: "middle", backgRoundColor: 'white'}}
          PaperProps={{style: {backgroundColor: "rgba(255, 255, 255, 1.0)", boxShadow: "none", justifyContent: "center", textAlign: "center", alignItems: "center"}}}
          tabIndex="0"
          onKeyDown={(e) => { 
            if(e.key === "ArrowLeft" && this.isLeftImageExist(this.state.image)) this.changeImage(-1)
            else if(e.key === "ArrowRight" && this.isRightImageExist(this.state.image)) this.changeImage(+1)
          }}
        >
          <DialogContent onClick={() => this.handleOpenModalShowClose()} style={{display: "contents"}}>
            <div style={{display: "inline-block", position: "absolute", top: "50%", left: 30}}>
              <IconButton aria-label="delete" onClick={(evt) => {evt.stopPropagation(); this.changeImage(-1)}} style={{width:20, height: 40, opacity: 0.7, visibility: this.isLeftImageExist(this.state.modalImage) ? "visible" : "hidden"}}>
                <Avatar src={leftArrow} style={{width:20, height: 40}}/>
              </IconButton>
            </div>
            <div style={{display: "inline-block"}}>
              <div style={{display: "inline-block", textAlign: "center"}}>
                <div id="image" style={{display: "inline-block", textAlign: "center", position: "relative"}}>
                  <img src={this.state.image.src} ref={this.state.imageModalImageRef} style={{maxWidth: window.innerWidth-50, maxHeight: window.innerHeight-50, verticalAlign: "middle"}} />
                  <div style={{position: "absolute", top: 10, right: 10}}>
                    <IconButton edge="start" color="inherit" onClick={(evt) => {evt.stopPropagation(); this.handleOpenModalShowClose()}} style={{width: 30, height: 30, padding: 0, marginRight: 10, marginBottom: 10}}>
                      <ZoomOutIcon style={{width:30, height: 30, color: 'white'}}/>
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
            <div style={{display: "inline-block", position: "absolute", top: "50%", right: 30}}>
              <IconButton aria-label="delete" onClick={(evt) => {evt.stopPropagation(); this.changeImage(+1)}} style={{width:20, height: 40, opacity: 0.7, visibility: this.isRightImageExist(this.state.modalImage) ? "visible" : "hidden"}}>
                <Avatar src={rightArrow} style={{width:20, height: 40}}/>
              </IconButton>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )

    return (
      <div style={{display: "flex", alignContent: "center", justifyContent: "center"}} className={classes.layoutRoot}>
        <div style={{display: "flex", flexDirection:"column", justifyContent: "center", width: 862}}>
          <div style={{height: 20}}/>
          <div className={classes.logo}><img src={logo}/></div>
          <div style={{width: 862, lineHeight: "700px"}}>
            {(this.state.image === undefined || this.state.image === null) ? "" : (
              <>
                <div style={{display: "inline-block", width: 20, height: 40}}>
                  <IconButton aria-label="delete" onClick={() => this.changeImage(-1)} style={{padding: 10, width: 20, height: 40, opacity: 0.7, visibility: this.isLeftImageExist(this.state.image) ? "visible" : "hidden"}}
                  >
                    <Avatar src={leftArrow} style={{width:20, height: 40}}/>
                  </IconButton>
                </div>
                <div style={{display: "inline-block", width: 822, textAlign: "center"}}>
                  <div style={{display: "inline-block", position: "relative", width: "100%", height: "100%"}}>
                    <img draggable="false" src={this.state.image.src} ref={this.state.imageRef} style={{maxWidth: 822, maxHeight: 685, verticalAlign: "middle"}} onLoad={() => {
                      if(this.state.imageRef.current != null) {
                        this.state.imageExpandRef.current.style.right  = (((822 - this.state.imageRef.current.offsetWidth)  / 2) + 10) + "px"
                        this.state.imageExpandRef.current.style.top    = (((700 - this.state.imageRef.current.offsetHeight) / 2) + 10) + "px"
                      }
                    }}/>
                    <IconButton ref={this.state.imageExpandRef} edge="start" color="inherit" onClick={() => {this.handleOpenModalShowOpen()}} style={{position: "absolute", top: 20, right: 20, width: 30, height: 30,padding: 0}}><ZoomInIcon style={{width: 30, height: 30, color: 'white'}} /></IconButton>
                  </div>
                  {this.state.imageModalShow ? imageModal : ""}
                </div>
                <div style={{display: "inline-block", width: 20, height: 40}}>
                  <IconButton aria-label="delete" onClick={() => this.changeImage(+1)}
                    style={{padding: 10, width: 20, height: 40, opacity: 0.7, visibility: this.isRightImageExist(this.state.image) ? "visible" : "hidden"}}
                  >
                    <Avatar src={rightArrow} style={{width:20, height: 40}}/>
                  </IconButton>
                </div>
              </>
            )}
          </div>
          <div style={{height: 20}}/>
          <div style={{lineHeight: "15px"}}>
            <span className={classes.copyrightMessage}>Copyright ⓒ by_stealth 2021 All rights Reserved</span><div style={{display: "inline-block", width:20}}/><a href="https://www.instagram.com/by_stealth" target='_blank'><img src={instagram} /><div style={{display: "inline-block", width:20}}/></a><a href="mailto:stealth0707@naver.com"><img src={mail}/></a>
          </div>
        </div>
        <div style={{width: 20}}/>
        <div style={{display: "inline-block", width: 142, flexDirection:"column"}}>
          <ImageSet ref={this.state.imageSetRef} imagePerPage={5}
            album={ this.queryString['album'] }
            onImageClick={(image) => {this.state.image = image; this.state.modalImage = image; this.setState(this.state)}}
          />
        </div>
      </div>
    )
  }
}
export default withStyles(styles)(LayoutPC)
