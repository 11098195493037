import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { orange } from '@material-ui/core/colors';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgb(86, 100, 210)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    /*
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    */
    height: 50
  },
}))(TableRow);

const ColoredCheckbox = withStyles({
  root: {
    color: orange[400],
    '&$checked': {
      color: orange[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class PhotoTable extends React.Component {
  options = {
    checkboxSelection: false,
    pagePerCount: 10,
    columns: [],
    onSelected: null,
  }
  state = {
    rows: [],
    selectAll: false,
    selected: {},
    currentPage: 0,
    totalRows: 0,
    lastUpdateTime : 0,
  }

  constructor(props) {
    super(props)
    this.props = props

    this.options.checkboxSelection  = props.checkboxSelection === undefined || props.checkboxSelection === null ? [] : props.checkboxSelection
    this.options.pagePerCount       = props.pagePerCount === undefined || props.pagePerCount === null ? 10 : props.pagePerCount
    this.options.rows               = props.rows === undefined || props.rows === null ? [] : props.rows
    this.options.columns            = props.columns === undefined || props.columns === null ? [] : props.columns
    this.options.onSelected         = props.onSelected === undefined ? null : props.onSelected

    this.fetchPageData = props.fetchPageData
    this.handlePageChange(0)

    this.setState(this.state)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lastUpdateTime > this.state.lastUpdateTime) {
      this.state.lastUpdateTime = nextProps.lastUpdateTime
      this.handlePageChange(this.state.currentPage)
      this.setState(this.state)
    }
  }

  handleSelectAll(evt) {
    if(this.state.selectAll === false) {
      Object.keys(this.state.selected).forEach(id => {
        this.state.selected[id] = true
      })
      this.state.selectAll = true
    } else {
      Object.keys(this.state.selected).forEach(id => {
        this.state.selected[id] = false
      })
      this.state.selectAll = false
    }
    this.setState(this.state)
    this.callbackOnSelect()
  }

  handleSelect(index, checked) {
    this.state.selectAll = false
    this.state.selected[index] = checked

    var selectAll = true
    Object.keys(this.state.selected).forEach(id => {
      selectAll = selectAll && this.state.selected[id]
    })
    this.state.selectAll = selectAll

    this.setState(this.state)
    this.callbackOnSelect()
  }

  callbackOnSelect() {
    if(this.options.onSelected !== null) {
      const values = []
      Object.entries(this.state.selected).forEach(entry => {
        const index = entry[0]
        if(entry[1]) {
          values.push(this.state.rows[parseInt(index)])
        }
      })
      this.options.onSelected(values)
    }
  }

  componentDidUpdate(prevProps) {
    if("reload" in this.props && this.props.reload) {
      this.refreshRows()
      this.props.setReload(false)
    }
  }

  refreshRows() {
    this.options.rows = this.props.rows === undefined || this.props.rows === null ? [] : this.props.rows
  }

  handlePageChange(page) {
    this.state.currentPage    = page
    this.state.selectAll      = false
    this.state.selected       = { }
    this.state.lastUpdateTime = Date.now()
    this.setState(this.state)

    this.fetchPageData(this.state.currentPage, this.options.pagePerCount)
    .then(res => {
      this.state.totalRows  = res.total
      this.state.rows       = res.rows
      this.setState(this.state)

      if(this.state.rows.length == 0) {
        if(page > 0) {
          this.handlePageChange(page-1)
        }
      } else {
        var index = 0
        this.state.rows.forEach(row => {
          this.state.selected[index++] = false
        })
      }
    })
  }

  changePage(page) {
    this.setState({currentPage: page})
    return page
  }

  render() {
    const pageRowCount = (this.options.pagePerCount < this.state.rows.length) ? this.options.pagePerCount : this.state.rows.length
    return (
      <TableContainer component={Paper}>
        <Table style={{width: "100%"}}>
          <TableHead>
            <TableRow>
              {this.options.checkboxSelection ? <StyledTableCell style={{width: 5}}><ColoredCheckbox checked={this.state.selectAll} onChange={evt => this.handleSelectAll()} /></StyledTableCell> : ""}
              {this.options.columns.map(column => {
                return (
                  <StyledTableCell key={column.field} width={column.width} align={column.align === undefined ? "center" : column.align}>{column.headerName !== undefined ? column.headerName: column.field}</StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
              {Array.from(Array(pageRowCount).keys()).map(index => {
                const row = this.state.rows[index]
                return (
                  <StyledTableRow key={"ROW"+index}>
                    {this.options.checkboxSelection ? <StyledTableCell><Checkbox checked={this.state.selected[index]||false} onChange={evt => this.handleSelect(index, evt.target.checked)} color="primary" /></StyledTableCell> : ""}
                    {this.options.columns.map(column => {
                      return (
                        <StyledTableCell key={"ROWCELL" + index + "-" + column.field} width={column.width} align={column.align === undefined ? "center" : column.align}>{row[column.field]}</StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                )
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination rowsPerPage={this.options.pagePerCount} count={this.state.totalRows}
                page={this.state.currentPage}
                onPageChange={(evt, page) => this.handlePageChange(page)}
                rowsPerPageOptions={[this.options.pagePerCount]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }
}