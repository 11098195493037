import React from "react";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/core dialog componetns
import Dialog from '@material-ui/core/Dialog';
// Images
import logo from "layout/resource/image/mobile_logo_b.png";
import instagram from "layout/resource/image/mobile_instagram_b.png";
import styles from "layout/layoutMobileStyle.js";
// Fonts
import "layout/resource/fonts/fonts.css"
import "layout/layoutMobile.css"
// ImageSet
import ImageSet from "component/thumbnail/ImageSet.js"
// 3rd-party
import QueryString from "qs";

const IMAGES_PER_LINE         = 1
const IMAGE_FETCH_LINE_COUNT  = 6
const IMAGE_FETCH_COUNT       = IMAGE_FETCH_LINE_COUNT * IMAGES_PER_LINE


class LayoutMobile extends React.Component {
  state = {
    init : false,
    image: null,
    imageModalShow: false,
  }


  constructor(props) {
    super(props)
    this.queryString    = QueryString.parse(window.location.search.substr(1))
    console.log(window.innerHeight)
  }

  handleOpenModalShowOpen(image) { this.setState({ image: image, imageModalShow : true }) }

  handleOpenModalShowClose() { this.setState({ imageModalShow : false }) }

  render() {
    const {classes} = this.props;
    const imageModal = (
      <Dialog open={this.state.imageModalShow} onClose={() => this.handleOpenModalShowClose()} fullScreen={true} maxWidth={false}
        PaperProps={{style: {backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: "none", justifyContent: "center", textAlign: "center"}}}
      >
        <div style={{display: "inline-block"}}>
          <div style={{ display: "inline-block", textAlign: "center", maxWidth: window.innerWidth, maxHeight: window.innerHeight, }} onClick={() => this.handleOpenModalShowClose()}>
            {this.state.image === null ? "" : <img alt="mainimg" src={this.state.image.src} style={{maxWidth: window.innerWidth, maxHeight: window.innerHeight}}/>}
          </div>
        </div>
      </Dialog>
    )

    return (
      <div className={classes.layoutRoot}>
        <div style={{height: 60}}>
          <div style={{marginLeft: 20, marginTop: 20, textAlign: "left", width: 40}}><a href="https://www.instagram.com/by_stealth" target='_blank'><img style={{ width: 20, height: 20 }} src={instagram} alt=""/></a></div>
        </div>
        <div style={{display: "inline-block", textAlign: "center", width: 317 }}>
          <img src={logo} style={{ width: 158, height: 42 }} alt="" />
        </div>
        <div style={{height: 30}} />
        {imageModal}
        <ImageSet mode="grid" imagePerPage={IMAGE_FETCH_COUNT}
          initialImages={15}
          album={ this.queryString['album'] }
          onInitialized={() => this.setState({ init: true }) }
          onImageClick={(image) => { if(this.state.init) this.handleOpenModalShowOpen(image) }}
        />
        <div style={{lineHeight: "20px", marginTop: 70, marginBottom: 40, color: 'black'}}>
          <span className={classes.copyrightMessage}>Copyright ⓒ by_stealth 2021 All rights Reserved</span>
        </div>
      </div>
    )
  }
}
export default withStyles(styles)(LayoutMobile)