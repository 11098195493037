import React from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// List
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
// Icon
import LockIcon from '@material-ui/icons/Lock';
// Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
// Cropper
import crypto from 'crypto-js';


export default function MyPage() {
  const [changePassword, setChangePassword] = React.useState(false)
  const [password, setPassword] = React.useState("")

  const sendChangePassword = function() {
    const data = new FormData()
    data.append("password", crypto.SHA256(password))

    fetch(`/api/v1/admin`, { method: 'put', body: data })
    .then(res => res.json())
    .then(res => {
      setChangePassword(false)
      alert("비밀번호가 변경되었습니다!")
    })
  }

  return (
    <div style={{ height: "100%" }}>
      <Accordion square expanded={true} style={{
          margin: "10px 0px", border: '1px solid rgba(0, 0, 0, .125)', boxShadow: 'none',
          '&:not(:lastChild)': { borderBottom: 0, }, '&:before': { display: 'none', }, '&$expanded': { margin: 'auto', },
        }}
        onChange={() => { }}
      >
        <AccordionSummary style={{ width: "100%", backgroundColor: 'rgba(0, 0, 0, .03)', borderBottom: '1px solid rgba(0, 0, 0, .125)', marginBottom: -1, minHeight: 56, '&$expanded': { minHeight: 56, },}}>
          <Typography style={{ width: "100%" }}>&nbsp;&nbsp;관리자 정보 수정</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 2, width: "100%" }}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav" >
            <ListItem alignItems="flex-start">
              <ListItemAvatar><LockIcon/></ListItemAvatar>
              <ListItemText primary="비밀번호 변경" secondary={
                <div sytle={{marginTop: 25}}>
                  <TextField style={{display: changePassword ? "inline-block" : "none" }} size="small" variant="outlined" type="password" label={"비밀번호"}
                    value={password} onChange={(event) => setPassword(event.target.value)}
                  />
                  &nbsp;
                  {!changePassword ? <Button onClick={() => setChangePassword(true)}>변경하기</Button> : <Button onClick={sendChangePassword}>변경</Button>}
                  {changePassword ? <Button color="secondary" onClick={() => setChangePassword(false)}>취소</Button> : ""}
                </div>
              }/>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
