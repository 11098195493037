import React from "react";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
// Table
import PhotoTable from "component/table/Table";
// Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Backdrop
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// Cropper
import Cropper from "react-cropper";
import NoPhoto from "static/noPhoto.gif"
import 'cropperjs-react';


function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
}


function ModelUpload(props) {
  const modelInfo = props.model
  const onComplete = props.onComplete
  const onCancle = props.onCancle
  const onProgress = props.onProgress
  const [expand, setExpand] = React.useState(false)
  const [step, setStep] = React.useState(0)
  const [stepProcessable, setStepProcessable] = React.useState(false)
  const [modelName, setModelName] = React.useState("")  
  const [uploadImage, setUploadImage] = React.useState(null)
  const [cropper, setCropper] = React.useState(null)
  const cropperRef = React.createRef()
  const photoSelectRef = React.createRef()

  React.useEffect(() => {
    if (modelInfo !== null) {
      setExpand(true)
      setStep(1)
      setStepProcessable(true)
      setModelName(modelInfo.name)
      setUploadImage({ src : modelInfo.src, photoId: null, file : modelInfo.file, createtime: null })
    } else {
      setExpand(false)
      setStep(0)
      setStepProcessable(false)
      setModelName("")
      setUploadImage(null)
    }
  }, [modelInfo])

  const onUploadImageSelected = function(event) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0]
  
      let reader = new FileReader()
      reader.onloadend = () => {
        var img = new Image()
        img.onload  = () => {
          setUploadImage({ src : reader.result, width : img.width, height : img.height, photoId: null, file : file, createtime: file.lastModified })
          setStepProcessable(true)
        }
        img.src = reader.result
      }
      reader.readAsDataURL(event.target.files[0])
    } else {
      setUploadImage(null)
    }
  }

  const setModelImage = function() {
    if (typeof cropper !== "undefined" && cropper !== null) {
      uploadImage.thumbnail = cropper.getCroppedCanvas({ width: 300, height: 300, imageSmoothingQuality: 'high' }).toDataURL()
      setStepProcessable(true)
    }
  }

  const sendUploadRequest = function() {
    if(onProgress) onProgress()

    const data = new FormData()
    if(modelInfo !== null) {
      data.append("id",       modelInfo.id)
      data.append("photoId",  modelInfo.photoId)
    }
    data.append("name", modelName)
    data.append("photo", dataURItoBlob(uploadImage.thumbnail))

    fetch(`/api/v1/model`, { method: 'POST', headers: {}, body: data, })
    .then(res => res.json())
    .catch(error => {
      alert("서버 업로드에 실패했습니다!")
      return null
    })
    .then(res => {
      if(res !== null) {
        setExpand(false)
        setStepProcessable(false)
        setModelName("")
        setUploadImage(null)
        if (onComplete) onComplete()
      }
    })
  }

  const stepSetModel = {
    label: "모델 입력",
    nextStepReady: function() { return modelName !== null },
    tag: (
      <div>
        <Typography>대상 모델을 입력해주세요</Typography>
        <div style={{margin: "10px 0px", width: "100%", textAlign: "center"}}>
          <TextField label="모델 이름을 입력하세요" variant="outlined" inputProps={{ autoComplete: 'new-password', width: "60%"}}
            style={{width: "60%"}} value={modelName}
            onChange={(event) => { setModelName(event.target.value); setStepProcessable(modelName !== null) }}
          />
        </div>
      </div>
    )
  }

  const stepSelectPhotos = {
    label : "사진 선택 & 썸네일 선택",
    nextStepReady: function() { return uploadImage !== null },
    onNext: function() { setModelImage() },
    tag: (
      <div>
        <Typography>업로드할 사진을 선택해주세요</Typography>
        <div style={{display: "inline-block", textAlign: "center", margin: "10px 0px"}}>
          {uploadImage === null ?
            <div style={{width: 300, height: 300, border: "2px solid grey", cursor: "pointer"}}>
              <img alt="noimg" src={NoPhoto} style={{width: 150, height: 150, transform: "translateY(50%)"}} onClick={() => { photoSelectRef.current.click() }}/>
            </div> : 
            <Cropper src={uploadImage.src} style={{ height: 300, width: 300 }} ref={cropperRef}
              initialAspectRatio={1} aspectRatio={1} guides={true} background={false} responsive={true} checkOrientation={false} zoomable={false} 
              autoCropArea={1} cropBoxMovable={false} cropBoxResizable={false} scalable={true} minCropBoxHeight={150} minCropBoxWidth={150} minContainerWidth={150} minContainerHeight={150}
              viewMode={1} dragMode="move" onInitialized={(instance) => { setCropper(instance); instance.setCropBoxData({width: 300, hegiht: 300}) }}
            />
          }
          <input type="file" name="photo" ref={photoSelectRef} required onChange={onUploadImageSelected} style={{display: "none"}} />
          <Button variant="contained" style={{marginTop: 10}} onClick={() => photoSelectRef.current.click() }>사진 선택</Button>
        </div>
      </div>
    )
  }

  const stepUpload = {
    label : "업로드",
    nextStepReady: function() { return true },
    onNext: function() { sendUploadRequest() },
    tag: (
      <div>
        <Typography>최종 업로드할 내용을 확인해주세요</Typography>
        <div><img alt="uploadimg" src={uploadImage === null ? NoPhoto: uploadImage.thumbnail} /></div>
        <div style={{display: "inline-block", textAlign: "center"}}>모델 : <b>{modelName}</b></div>
        <Typography>위와 같이 업로드하시려면 '완료'를 클릭하세요</Typography>
        <br/>
      </div>
    )
  }

  const steps = [stepSetModel, stepSelectPhotos, stepUpload]  
  return (
    <Accordion square expanded={expand} style={{ margin: "10px 0px", border: '1px solid rgba(0, 0, 0, .125)', boxShadow: 'none',
      '&:no t(:lastChild)': { borderBottom: 0, }, '&:before': { display: 'none', }, '&$expanded': { margin: 'auto', }, }}
      onChange={() => setExpand(!expand)}
    >
      <AccordionSummary style={{ width: "100%", backgroundColor: 'rgba(0, 0, 0, .03)', borderBottom: '1px solid rgba(0, 0, 0, .125)', marginBottom: -1, minHeight: 56, '&$expanded': { minHeight: 56, },}}>
        <Typography style={{ width: "100%" }}>&nbsp;&nbsp;모델 업로드</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 2, width: "100%" }}>
        <Stepper activeStep={step} alternativeLabel>{steps.map(stepInstance => (<Step key={stepInstance.label}><StepLabel>{stepInstance.label}</StepLabel></Step>))}</Stepper>
        <div style={{textAlign: "center", marginBottom: 15, width: "100%"}}>
          <div>{steps[step].tag}</div>
          <Button disabled={step === 0} variant="contained" color="secondary" onClick={() => setStep(step-1)}>이전</Button>
          <div style={{ display: "inline-block", width: 10 }}>&nbsp;</div>
          <Button disabled={!stepProcessable} variant="contained" color="primary"
            onClick={() => {
              const nextStep = (step+1) % steps.length

              if(steps[step].onNext !== undefined) steps[step].onNext()
              setStep(nextStep)
              setStepProcessable(steps[nextStep].nextStepReady())
          }}>
            {step === steps.length - 1 ? '완료' : '다음'}
          </Button>
          {modelInfo === null ? "" : (<>&nbsp;&nbsp;<Button variant="contained" color="secondary" onClick={() => { if(onCancle) onCancle() }}>취소</Button></>)}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}


export default class ModelAdmin extends React.Component {
  state = {
    fetchedImages     : [],
    selectedModels    : [],
    lastUpdateTime    : Date.now(),
    progress          : false,
    // * Fetch operation related variables.
    fetchCondition    : { model : "" },
    // * Modification oeration related variables.
    modelInfo         : null,
    // * Delete operation related variables.
    deleteIds         : [],
    openDeleteDialog  : false,
    // * Result Dialog
    resultDialog      : { open : false, title : "", content : "", }
  }

  columns = [
    { field: 'id',        headerName: 'ID',       width: 50  },
    { field: 'thumbnail', headerName: '썸네일',   width: 80  },
    { field: 'name',      headerName: '이름',     width: 100 },
    { field: 'modify',    headerName: '수정',     width: 10  },
    { field: 'delete',    headerName: '삭제',     width: 10  },
  ]

  uploadStepLabels = ["모델 선택", "사진 선택 & 썸네일 선택", "최종 업로드"]


  constructor(props) {
    super(props)

    this.onDelete = this.onDelete.bind(this)
  }

  fetchModel(currentPage, requestImageCount) {
    if(this.state.progress) return

    const startIndex = currentPage * requestImageCount
    var uri = `/api/v1/model?start=${startIndex}&pagePerPhotos=${requestImageCount}`
    if(this.state.fetchCondition.model !== null && this.state.fetchCondition.model !== "") {
      uri += `&type=name&name=${this.state.fetchCondition.model}`
    }

    this.setState({progress : true})    
    return fetch(uri)
    .then(res => res.json())
    .then(res => {
      const models = []
      res.result.models.forEach(model => {
        const modelRow = {
          id : model.id,
          thumbnail : (<img alt={model.name} src={`/static/model/${model.photoId}`} style={{width: 80, height: 80}} />),
          name : model.name,
          modify : (<Button variant="contained" color="primary"   onClick={() => { this.modifyModel(model) }}>수정</Button>),
          delete : (<Button variant="contained" color="secondary" onClick={() => { this.setState({openDeleteDialog: true, deleteIds: [model.id]}) }}>삭제</Button>)
        }
        models.push(modelRow)
      })
      this.setState({progress: false})
      return { total: res.result.totalCount, rows: models  }
    })
  }

  deleteImages(images) {
    if(this.state.progress) return

    if(images.length > 0) {
      this.setState({ progress: true })

      fetch(`/api/v1/model?id=${images}`, { method: 'delete' })
      .then(res => res.json())
      .then(res => {
        if (res.result.modelPhotos !== undefined && res.result.modelPhotos !== null) {
          var photoCount = 0
          res.result.modelPhotos.forEach(model => {
            photoCount += model.count
          })
          this.setState({
            resultDialog: {
              open: true,
              title: (<><ErrorIcon color="secondary"/> 모델 삭제 실패</>),
              content: (
                <>
                  삭제하시고자 하는 모델의 사진이 발견되었습니다!<br/>
                  모델의 사진을 모두 지우고 다시 시도해주세요<br/>
                  <br/>
                  발견된 사진 : 총 {photoCount} 건
                </>
              )
            },
            progress: false
          })
        } else {
          if(JSON.stringify(res.result.deleteIds.sort().map(id => parseInt(id))) === JSON.stringify(images.sort())) {
            this.setState({lastUpdateTime : Date.now(), progress: false})
          } else {
            alert("삭제 도중 문제가 발생했습니다")
            this.setState({progress: false})
          }
        }
      })
    }
  }

  modifyModel(model) {
    if(this.state.progress) return

    this.setState({progress: true})
    fetch(`/static/model/${model.photoId}`)
    .then(res => res.status === 200 ? res.blob() : null)
    .catch(error => { alert("서버에서 올바른 응답이 오지 않습니다!"); return null })
    .then(res => {
      if(res !== null) {
        this.setState({ modelInfo : {...model, src: `/static/model/${model.photoId}`, file : new File([res], model.photoId) }})
      } else {
        alert("서버에서 올바른 응답이 오지 않습니다!")
      }
      this.setState({progress: false})
    })
  }

  onDelete() {
    const models = []
    this.state.selectedModels.forEach(image => {
      models.push(image.id)
    })
    this.deleteImages(models)
  }

  closeDialog() {
    this.setState({openDeleteDialog: false})
  }

  closeResultDialog() {
    this.setState({ resultDialog: { open: false } })
  }

  render() {
    const rows = this.state.fetchedImages
    return (
      <div>
        <Backdrop open={this.state.progress} style={{zIndex: 1000}}><CircularProgress color="inherit" /></Backdrop>

        <ModelUpload model={this.state.modelInfo} onProgress={() => this.setState({ progress: true })}
          onComplete={() => this.setState({lastUpdateTime: Date.now(), modelInfo: null, progress: false})} onCancle={() => this.setState({ modelInfo : null }) } />
        
        <div style={{position: "absolute", display: "inline-block" }}>
          <TextField ref={this.refSearchCondition} size="small" variant="outlined" label={"모델 이름으로 검색"} onChange={(event) => { this.setState({ fetchCondition: { model: event.target.value } }) }}/>
          <IconButton onClick={() => { this.setState({ lastUpdateTime: Date.now() }) }} style={{verticalAlign: "top", height: 40, marginLeft: 5}}>
            <SearchIcon />
          </IconButton>
        </div>
        <div style={{position: "absolute", right: 30, display: "inline-block" }}>
          <Button variant="contained" color="secondary" disabled={this.state.selectedModels.length === 0} onClick={this.onDelete.bind(this)}>모두 삭제</Button>
        </div>

        <div style={{width: "100%", height : 500, textAlign: "center", display: "inline-block", marginTop: 50}}>
          <PhotoTable rows={rows} columns={this.columns}
            lastUpdateTime={this.state.lastUpdateTime}
            pagePerCount={10}
            checkboxSelection
            onSelected={(selected) => this.setState({ selectedModels : selected})}
            fetchPageData={this.fetchModel.bind(this)}
            style={{display: "inline-block"}}
          />
        </div>

        <Dialog open={this.state.openDeleteDialog} onClose={this.closeDialog.bind(this)}>
          <DialogTitle>{"데이터 삭제하기"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              선택하신 모델이 완전히 삭제되며, 다시는 복구할 수 없습니다<br/>
              정말로 삭제하시겠습니까?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog.bind(this)} color="primary">
              그만두기
            </Button>
            <Button onClick={() => {this.deleteImages(this.state.deleteIds); this.closeDialog()}} color="secondary">
              삭제하기
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.resultDialog.open} onClose={this.closeResultDialog.bind(this)}>
          <DialogTitle>{this.state.resultDialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.resultDialog.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeResultDialog.bind(this)} color="primary">
              닫기
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
