import React from "react";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Backdrop
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// Cropper
import Cropper from "react-cropper";
import NoPhoto from "static/noPhoto.gif"
// Table
import PhotoTable from "component/table/Table";
import 'cropperjs-react';


function formatDate(time) {
  const date  = new Date(time)
  const pad   = function(time) { return time < 10 ? '0'+time : time }
  return `${date.getFullYear()}-${pad(date.getMonth())}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
}

function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
}

function donwloadFile(blob, filename) {
  if(blob === null) return
  
  var url = window.URL.createObjectURL(blob)
  var a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  a.remove()
}


function PhotoUpload(props) {
  const photoInfo = props.photo
  const onComplete = props.onComplete
  const onCancle = props.onCancle
  const onProgress = props.onProgress
  const [uploading, setUploading] = React.useState(false)
  const [expand, setExpand] = React.useState(false)
  const [step, setStep] = React.useState(0)
  const [stepProcessable, setStepProcessable] = React.useState(false)
  const [modelCandidates, setModelCandidates] = React.useState([])
  const [modelId, setModelId] = React.useState(null)  
  const [modelName, setModelName] = React.useState("")  
  const [uploadImage, setUploadImage] = React.useState(null)
  const [tags, setTags] = React.useState("")
  const [cropper, setCropper] = React.useState(null)
  const cropperRef = React.createRef()
  const photoSelectRef = React.createRef()

  const init = function() {
    setUploading(false)
    setExpand(false)
    setStep(0)
    setStepProcessable(false)
    setModelName("")
    setUploadImage(null)
    setTags("")
  }

  React.useEffect(() => {
    if (photoInfo !== null) {
      setExpand(true)
      setStep(1)
      setStepProcessable(true)
      setModelId(photoInfo.modelId)
      setModelName(photoInfo.model)
      setUploadImage({ src : photoInfo.src, photoId: null, file : photoInfo.file, width: photoInfo.width, height: photoInfo.height })
      setTags(photoInfo.tags)
    } else {
      init()
    }
  }, [photoInfo])

  const onUploadImageSelected = function(event) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0]
  
      let reader = new FileReader()
      reader.onloadend = () => {
        var img = new Image()
        img.onload  = () => {
          setUploadImage({ src : reader.result, width : img.width, height : img.height, photoId: null, file : file, createtime: file.lastModified })
          setStepProcessable(true)
        }
        img.src     = reader.result
      }
      reader.readAsDataURL(event.target.files[0])
    } else {
      setUploadImage(null)
    }
  }

  const setModelImage = function() {
    if (typeof cropper !== "undefined" && cropper !== null) {
      uploadImage.thumbnail = cropper.getCroppedCanvas({ width: 300, height: 300, imageSmoothingQuality: 'high' }).toDataURL()
      setStepProcessable(true)
    }
  }

  const sendUploadRequest = function() {
    if(uploading) return

    setUploading(true)
    const data = new FormData()
    if(photoInfo !== null) {
      data.append("id",       photoInfo.id)
      data.append("photoId",  photoInfo.photoId)
    }
    data.append("model",      modelId)
    data.append("photo",      uploadImage.file)
    data.append("thumbnail",  dataURItoBlob(uploadImage.thumbnail))
    data.append("createTime", uploadImage.file.lastModified)
    data.append("width",      uploadImage.width)
    data.append("height",     uploadImage.height)
    data.append("tags",       tags === undefined || tags === null || tags.length === 0 ? "" : tags)

    if (onProgress) onProgress()
    fetch(`/api/v1/photo`, { method: 'POST', headers: {}, body: data, })
    .then(res => {
      init()
      if (onComplete) onComplete()
    })
    .catch(error => {
      alert("서버 업로드에 실패했습니다!")
    })
  }

  const stepSetModel = {
    label: "모델 선택",
    nextStepReady: function() { return modelName !== null },
    tag: (
      <div>
        <Typography>대상 모델을 입력해주세요</Typography>
        <div style={{margin: "10px 0px", width: "100%", textAlign: "center"}}>
          <Autocomplete style={{margin: "10px 20%",}} options={modelCandidates} autoHighlight getOptionLabel={option => option.name} inputValue={modelName}
            renderOption={option => (<React.Fragment><img alt={option.photoId} src={`/static/model/${option.photoId}`} style={{ width: 30, height: 30 }} />&nbsp;{option.name}</React.Fragment>)}
            onOpen={() => {
              if(modelCandidates.length === 0) {
                fetch(`/api/v1/model?start=${0}&pagePerPhotos=${10}&type=name&name=`)
                .then(res => res.json())
                .then(res => setModelCandidates(res.result.models))
              }
            }}
            onChangeCapture={(event) => setModelName(event.target.value)}
            onChange={(_, newValue) => {
              if(newValue === null) {
                setModelId(null)
                setModelName("")
                setStepProcessable(false)
              } else {
                setModelId(newValue.id)
                setModelName(newValue.name)
                setStepProcessable(true)
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="모델 이름을 입력하세요" variant="outlined" inputProps={{...params.inputProps, autoComplete: 'new-password', }}
                onChange={event => {
                  fetch(`/api/v1/model?start=${0}&pagePerPhotos=${10}&type=name&name=${event.target.value}`).then(res => res.json()).then(res => setModelCandidates(res.result.models))
                }}
              />
            )}
          />
        </div>
      </div>
    )
  }

  const stepSelectPhotos = {
    label : "사진 선택 & 썸네일 선택",
    nextStepReady: function() { return uploadImage !== null },
    onNext: function() { setModelImage() },
    tag: (
      <div>
        <Typography>업로드할 사진을 선택해주세요</Typography>
        <div style={{display: "inline-block", textAlign: "center", margin: "10px 0px"}}>
          {uploadImage === null ?
            <div style={{width: 300, height: 300, border: "2px solid grey", cursor: "pointer"}}>
              <img alt="noimg" src={NoPhoto} style={{width: 150, height: 150, transform: "translateY(50%)"}} onClick={() => { photoSelectRef.current.click() }}/>
            </div> : 
            <Cropper src={uploadImage.src} style={{ height: 300, width: 300 }} ref={cropperRef}
              initialAspectRatio={1} aspectRatio={1} guides={true} background={false} responsive={true} checkOrientation={false} zoomable={false} 
              autoCropArea={1} cropBoxMovable={false} cropBoxResizable={false} scalable={true} minCropBoxHeight={150} minCropBoxWidth={150} minContainerWidth={150} minContainerHeight={150}
              viewMode={1} dragMode="move" onInitialized={(instance) => { setCropper(instance); instance.setCropBoxData({width: 300, hegiht: 300}) }}
            />
          }
          <input type="file" name="photo" ref={photoSelectRef} required onChange={onUploadImageSelected} style={{display: "none"}} />
          <Button variant="contained" style={{marginTop: 10}} onClick={() => photoSelectRef.current.click() }>사진 선택</Button>
        </div>
      </div>
    )
  }

  const stepUpload = {
    label : "업로드",
    nextStepReady: function() { return true },
    onNext: function() { sendUploadRequest() },
    tag: (
      <div>
        <Typography>최종 업로드할 내용을 확인해주세요</Typography>
        <div><img alt="uploadImage" src={uploadImage === null ? NoPhoto: uploadImage.thumbnail} /></div>
        <div style={{display: "inline-block", textAlign: "center"}}>모델 : <b>{modelName}</b></div>
        <br/>
        <TextField label="태그" variant="outlined" autoFocus size="small" margin="dense" value={tags} onChange={(event) => setTags(event.target.value)} />
        <Typography>태그를 입력하시고, 업로드하시려면 '완료'를 클릭하세요</Typography>
      </div>
    )
  }

  const steps = [stepSetModel, stepSelectPhotos, stepUpload]  
  return (
    <Accordion square expanded={expand} style={{ margin: "10px 0px", border: '1px solid rgba(0, 0, 0, .125)', boxShadow: 'none',
      '&:no t(:lastChild)': { borderBottom: 0, }, '&:before': { display: 'none', }, '&$expanded': { margin: 'auto', }, }}
      onChange={() => setExpand(!expand)}
    >
      <AccordionSummary style={{ width: "100%", backgroundColor: 'rgba(0, 0, 0, .03)', borderBottom: '1px solid rgba(0, 0, 0, .125)', marginBottom: -1, minHeight: 56, '&$expanded': { minHeight: 56, },}}>
        <Typography style={{ width: "100%" }}>&nbsp;&nbsp;사진 업로드</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 2, width: "100%" }}>
        <Stepper activeStep={step} alternativeLabel>{steps.map(stepInstance => (<Step key={stepInstance.label}><StepLabel>{stepInstance.label}</StepLabel></Step>))}</Stepper>
        <div style={{textAlign: "center", marginBottom: 15, width: "100%"}}>
          <div>{steps[step].tag}</div>
          <Button disabled={step === 0} variant="contained" color="secondary" onClick={() => setStep(step-1)}>이전</Button>
          <div style={{ display: "inline-block", width: 10 }}>&nbsp;</div>
          <Button disabled={!stepProcessable} variant="contained" color="primary"
            onClick={() => {
              const nextStep = (step+1) % steps.length

              if(steps[step].onNext !== undefined) steps[step].onNext()
              setStep(nextStep)
              setStepProcessable(steps[nextStep].nextStepReady())
          }}>
            {step === steps.length - 1 ? '완료' : '다음'}
          </Button>
          {photoInfo === null ? "" : (<>&nbsp;&nbsp;<Button variant="contained" color="secondary" onClick={() => { if(onCancle) onCancle() }}>취소</Button></>)}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default class PhotoAdmin extends React.Component {
  state = {
    cropperRef        : React.createRef(),
    fetchedImages     : [],
    selectedImage     : [],
    lastUpdateTime    : Date.now(),
    pagePerCount      : 10,
    onProgress        : false,
    // * Fetch operation related variables.
    searchOption      : "model",
    searchKey         : "",
    // * Modification oeration related variables.
    photoInfo         : null,
    photoRefs         : { },
    photoRank         : { },
    // * Delete operation related variables.
    deleteIds         : [],
    openDeleteDialog  : false,
  }

  columns = [
    { field: 'rank',        headerName: '순번',         width: 5, /*valueGetter: (params) => `${params.getValue('id')}`*/ },
    { field: 'thumbnail',   headerName: '썸네일',       width: 80, /*valueGetter: (params) => `${params.getValue('id')}`*/ },
    { field: 'model',       headerName: '모델',         width: 100, sortable: true, },
    { field: 'uploadTime',  headerName: '업로드시간',   width: 75 },
    { field: 'tags',        headerName: '태그',         width: 100 },
    { field: 'download',    headerName: '다운로드',     width: 45  },
    { field: 'modify',      headerName: '수정/조회',    width: 25  },
    { field: 'delete',      headerName: '삭제',         width: 10  },
    { field: 'order',       headerName: '순서',         width: 10  },
  ]


  constructor(props) {
    super(props)

    this.deleteImages     = this.deleteImages.bind(this)
    this.onDelete         = this.onDelete.bind(this)
  }

  fetchImage(currentPage, requestImageCount) {
    this.setState({ selectedImage : [] })

    const startIndex = currentPage * requestImageCount
    return fetch(`/api/v1/photo?start=${startIndex}&pagePerPhotos=${requestImageCount}&searchOption=${this.state.searchOption}&searchKey=${this.state.searchKey}`)
    .then(res => res.json())
    .catch(error => { alert("서버와의 통신에서 문제가 발생했습니다"); return null })
    .then(res => {
      if(res === null) return { total: 0, rows: [] }

      this.setState({ rankTextRefs : { } })

      const images = []
      res.result.photos.forEach(photo => {
        const rank = startIndex + images.length + 1
        this.state.rankTextRefs[photo.id] = React.createRef()
        const ref = this.state.rankTextRefs[photo.id]
        const image = {
          id          : photo.id,
          ref         : ref,
          rank        : rank,
          thumbnail   : (<img alt={photo.photoId} src={`/static/thumbnails/${photo.photoId}`} style={{width: 80, height: 80}} />),
          model       : <div style={{ fontSize: 0 }}><Avatar sx={{ width: 32, height: 32 }} src={`/static/model/${photo.modelPhotoId}`} style={{ verticalAlign: "top", display: "inline-block" }} /><div style={{ verticalAlign: "top", display: "inline-block", height: 32, lineHeight: "32px", fontSize: 14, marginLeft: 5 }}>{photo.model}</div></div>,
          uploadTime  : formatDate(photo.registerTime),
          tags        : photo.tags === undefined || photo.tags.length === 0 ? "" : photo.tags,
          download    : (<Button variant="contained" color="primary" onClick={() => {
            fetch(`/api/v1/download?&photo=${photo.photoId}`)
            .then(res => res.status === 200 ? res.blob() : null )
            .catch(error => { alert("서버와의 통신에서 문제가 발생했습니다"); return null })
            .then(blob => { if(blob !== null) { donwloadFile(blob, `${photo.photoId}.png`) } else { alert("다운로드에 실패하였습니다") } })}}>다운</Button>
          ),
          modify      : (<Button variant="contained" color="primary"   onClick={() => { this.modifyPhoto(photo) }}>수정</Button>),
          delete      : (<Button variant="contained" color="secondary" onClick={() => { this.setState({openDeleteDialog: true, deleteIds: [photo.id]}) }}>삭제</Button>),
          order       : (
            <div>
              <IconButton variant="contained" color="secondary" onClick={e => this.changePhotoOrder(photo.id, +1)}><Icon>arrow_drop_up</Icon></IconButton>
              <IconButton variant="contained" color="secondary" onClick={e => this.changePhotoOrder(photo.id, -1)}><Icon>arrow_drop_down</Icon></IconButton>
              <TextField inputRef={this.state.rankTextRefs[photo.id]} defaultValue="" size="small" variant="standard" onChange={e => {
                this.state.photoRank[photo.id] = e.target.value
                this.setState({ photoRank: this.state.photoRank })
              }}></TextField>
              <IconButton variant="contained" color="secondary" onClick={e => {
                if(!this.state.photoRank[photo.id]) return
                
                this.changePhotoOrder(photo.id, image.rank - parseInt(this.state.photoRank[photo.id]))
                image.ref.current.value = ""
                this.setState({ photoRank: { } })
              }}><Icon>double_arrow</Icon></IconButton>
            </div>
          )
        }
        images.push(image)
        this.setState({ rankTextRefs : this.state.rankTextRefs })
      })
      return { total: res.result.totalCount, rows: images }
    })
  }

  deleteImages(images) {
    if(this.state.onProgress) return

    if(images.length > 0) {
      this.setState({ onProgress: true })
      fetch(`/api/v1/photo?id=${images}`, { method: 'delete' })
      .then(res => res.json())
      .catch(error => { alert("서버와의 통신에서 문제가 발생했습니다"); return null })
      .then(res => {
        if(JSON.stringify(res.result.deleteIds.sort().map(id => parseInt(id))) === JSON.stringify(images.sort())) {
          this.setState({lastUpdateTime : Date.now(), onProgress: false})
        } else {
          alert("삭제 도중 문제가 발생했습니다")
          this.setState({onProgress: false})
        }
      })
    }
  }

  modifyPhoto(image) {
    if(this.state.onProgress) return

    this.setState({ onProgress: true })
    fetch(`/static/gallery/${image.photoId}`)
    .then(res => res.status === 200 ? res.blob() : null )
    .catch(error => { alert("서버와의 통신에서 문제가 발생했습니다"); return null })
    .then(res => {
      if(res !== null) {
        this.setState({ photoInfo: { ...image, src: `/static/gallery/${image.photoId}`, file : new File([res], image.photoId) }, onProgress : false })
      } else {
        this.setState({ onProgress: false })
      }
    })
  }

  onDelete() {
    if(this.state.selectedImage.length > 0) {
      this.setState({ deleteIds : this.state.selectedImage.map(image => image.id), openDeleteDialog: true })
    }
  }

  onPhotoSelect(selected) {
    this.setState({selectedImage: selected})
  }

  changePhotoOrder(id, modified) {
    fetch(`/api/v1/photoorder?currentId=${id}&modified=${modified}`)
    .then(res => res.status === 200 ? res.blob() : null )
    .catch(error => { alert("서버와의 통신에서 문제가 발생했습니다"); return null })
    .then(res => {
      this.setState({lastUpdateTime : Date.now()})
    })
  }

  closeDialog() {
    this.setState({openDeleteDialog: false})
  }

  render() {
    const rows = this.state.fetchedImages
    return (
      <div>
        <Backdrop open={this.state.onProgress} style={{zIndex: 1000}}><CircularProgress color="inherit" /></Backdrop>

        <PhotoUpload photo={this.state.photoInfo} onProgress={() => this.setState({ onProgress: true })}
          onComplete={() => this.setState({ lastUpdateTime: Date.now(), photoInfo: null, onProgress: false })} onCancle={() => this.setState({ photoInfo: null })} />
                
        <div style={{position: "absolute", display: "inline-block" }}>
          <div style={{ width: 100, display: "inline-block" }}>
            <FormControl fullWidth>
              <InputLabel id="searchOptionLabel">검색옵션</InputLabel>
              <Select labelId="searchOptionLabel" value={this.state.searchOption} onChange={(event) => { this.setState({ searchOption: event.target.value }) }}>
                <MenuItem value="model" style={{ width: "100%" }}>모델</MenuItem>
                <MenuItem value="tags" style={{ width: "100%" }}>태그</MenuItem>
              </Select>
            </FormControl>
          </div>
          &nbsp;
          <TextField size="small" variant="outlined" label={"검색"} onChange={(event) => { this.setState({ searchKey: event.target.value }) }}/>
          <IconButton onClick={() => { this.setState({ lastUpdateTime: Date.now() }) }} style={{verticalAlign: "top", height: 40, marginLeft: 5}}>
            <SearchIcon />
          </IconButton>
        </div>
        <div style={{position: "absolute", right: 30, display: "inline-block" }}>
          <Button variant="contained" color="secondary" disabled={this.state.selectedImage.length === 0} onClick={this.onDelete.bind(this)}>모두 삭제</Button>
        </div>

        <div style={{width: "100%", height : 500, textAlign: "center", display: "inline-block", marginTop: 50}}>
          <PhotoTable rows={rows} columns={this.columns}
            lastUpdateTime={this.state.lastUpdateTime}
            pagePerCount={this.state.pagePerCount}
            checkboxSelection
            onSelected={this.onPhotoSelect.bind(this)}
            fetchPageData={this.fetchImage.bind(this)}
            style={{display: "inline-block"}}
          />
        </div>
        
        <Dialog open={this.state.openDeleteDialog} onClose={this.closeDialog.bind(this)}>
          <DialogTitle>{"데이터 삭제하기"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              선택하신 사진이 완전히 삭제되며, 다시는 복구할 수 없습니다<br/>
              정말로 삭제하시겠습니까?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog.bind(this)} color="primary">
              그만두기
            </Button>
            <Button onClick={() => {this.deleteImages(this.state.deleteIds); this.closeDialog()}} color="secondary">
              삭제하기
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
